/*
 * @Author: zzz
 * @Date: 2021-01-26 15:49:46
 * @LastEditTime: 2021-01-28 16:56:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \04eos-app\src\pages\rank\attr.js
 */
export const tabsData = [
  {
    title: '大定已付待跟进',
    label: 'largeSet'
  },
  {
    title: '待Hand-over',
    label: 'handOff'
  },
  {
    title: '意向金退款待跟进',
    label: 'intentionGold'
  }
]
// 订单状态
export const OrderStatusEnmu = {
  '00': '意向金未支付',
  '01': '已取消',
  '02': '意向金已支付',
  '03': '退款中',
  '04': '自动退款失败',
  '05': '已关闭',
  '06': '已失效',
  '10': '定金未支付',
  '20': '定金已付待确认',
  '21': '订单已锁配',
  '22': '定金已付尾款待付',
  '23': '首付未支付贷款未付',
  '24': '首付已付贷款未付',
  '30': '尾款已付待交付',
  '99': '已完成'
}
// 订单交付状态
export const OrderDeliverEnmu = {
  '100': '未销售确认',
  '00': '已确认',
  '10': '正在安排生产',
  '11': '生产中',
  '12': '生产下线',
  '13': '待发运',
  '20': '已做发运指令',
  '21': '发运指令取消中',
  '22': 'VDC已出库',
  '23': '车辆到达DH',
  '30': '车辆入库',
  '31': '待验车',
  '32': '待客户确认验车',
  '33': '已验车',
  '40': '待提车',
  '45': '待客户确认提车',
  '50': '车辆待交付',
  '60': '交付异常',
  '99': '已交付'
}
