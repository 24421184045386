<template>
  <Card class="allSearchCard">
    <template>
      <template slot="title">
        <div class="card-info">
          <h3 class="name">
            {{type === 'largeSet' ? cardInfo.accountName : cardInfo.orderNo}}
            <img
              style="width:20px;height:auto;vertical-align: middle;"
              src="../../../../assets/images/icon/male.png"
              v-if="cardInfo.gender === '1'"
            />
            <img
              style="width:20px;height:auto;vertical-align: middle;"
              src="../../../../assets/images/icon/girl.png"
              v-if="cardInfo.gender === '0'"
            />
            <template v-if="type === 'handOff' && new Date().getTime() - ((cardInfo.orderPaidTime ? cardInfo.orderPaidTime : (cardInfo.createtime) / 1000) * 1000) > 172800000">
              <span class="over">逾期</span>
            </template>
          </h3>
          <span class="tag">{{type === 'largeSet' ? cardInfo.oppStatus : cardInfo.orderStatus}}</span>
        </div>
        <div class="description">
          <h4
            class="accountMobile"
            v-if="type === 'largeSet'"
          >
            <a :href="`tel:${cardInfo.qbsAccountPhone}`">
              <Icon
                name="phone"
              />
            </a>
            {{cardInfo.accountPhone}}
          </h4>
          <h4
            class="accountMobile"
            style="display:flex;justify-content: space-between;"
            v-if="type === 'handOff' || type === 'intentionGold'"
          >
            <span>订单创建时间：{{cardInfo.createtime}}</span>
            <span
              v-if="type === 'intentionGold'"
              @click="goOrderDetail(cardInfo.orderNo)"
              style="color:#1292FF"
            >
              订单详情
              <Icon name="arrow" />
            </span>
          </h4>
        </div>
        <div
          v-if="type === 'largeSet'"
          @click="goOrderDetail(cardInfo.orderNo)"
          class="largeOrderNum"
        >
          <span>订单号:{{cardInfo.orderNo}}</span>
          <span>
            <Icon name="arrow" />
          </span>
        </div>
      </template>
      <div style="border-top:1px solid #eee;padding-top:10px">
        <template v-if="type === 'intentionGold'">
          <div
            class="card-info"
            style="color:#E5A428;line-height:2em;font-size:12px"
          >{{cardInfo.refundReason}}</div>
        </template>
        <template v-if="type !== 'largeSet'">
          <div
            class="card-info"
            @click="goAccountDetail(cardInfo.accountId)"
          >
            <h3 class="name">{{cardInfo.accountName}}</h3>
            <span style="color:#1899FF">
              客户详情
              <Icon
                name="arrow"
                color="#1899FF"
              />
            </span>
          </div>
        </template>
      </div>

      <template slot="footer">
        <span class="msg">
          <Icon name="manager" />
          {{cardInfo.ownerName}}
        </span>
        <div>
          <a :href="`tel:${cardInfo.qbsAccountPhone}`">
            <Icon
              name="phone-circle-o"
              size="30"
              color="#1899FF"
            />
          </a>
          <Button
            round
            v-if="type === 'largeSet'"
            type="info"
            style="margin-right:10px"
            size="small"
            @click="goAccountDetail(cardInfo.accountId)"
          >详情</Button>
          <Button
            round
            v-if="type === 'largeSet' || type === 'intentionGold'"
            type="info"
            style="margin-right:10px"
            size="small"
            @click="followup(cardInfo.oppId)"
          >跟进</Button>
          <Button
            round
            v-else
            type="info"
            size="small"
            @click="goOrderDetail(cardInfo.orderNo)"
          >订单详情</Button>
        </div>
      </template>
    </template>
  </Card>
</template>
<script>
import { Button, Icon, Toast } from "vant";
import Card from "_c/card";
import { orgCode } from '@/api/chargequery'
import "vant/lib/icon/style";
import "vant/lib/row/style";
import "vant/lib/col/style";
import "@/style/vant-patch.less";
import "./index.less";
export default {
  name: "CardItem",
  props: {
    cardInfo: {
      type: Object,
      require: true
    },
    type: {
      type: String,
      require: true
    }
  },
  data () {
    return {};
  },
  components: {
    Card,
    Button,
    Icon
  },
  methods: {
    init () {
      console.log(111);
    },
    // 跳转到订单详情
    goOrderDetail (orderNum) {
      this.$router.push({
        path: "/orderDetail",
        query: {
          orderNum
        }
      });
    },
    // 跳转到客户详情
    goAccountDetail (accountId) {
      this.$router.push({
        path: "/customerInfo",
        query: { id: accountId }
      });
    },
    // 跟进
    followup (id) {
      this.$router.push({
        path: "/businessOpportunitiesFollowUpFrom",
        query: {
          eosOppId: id,
          orderFollowup: "ORDER"
        }
      });
    },
    // 点击电话按钮
    handlephine (cardinfo) {
      const callphone = cardinfo.accountPhone
      this.orgCode(callphone)
    },
    // 拨打电话
    async orgCode (callphone) {
      const res = await orgCode({ orgCode: callphone }) //
      if (res.code === '000000') {
        Toast('Calling')
      } else {
        Toast(res.msg)
      }
    },
  },
  mounted () {
    this.init();
  }
};
</script>